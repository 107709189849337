import React, { useContext } from "react"
import Layout from "../../components/layout"

import r from "../../services/Router"
import { graphql, Link } from "gatsby"
import ThemeContext from "../../context/ThemeContext"
import GenericLandingContentWrapper from "../../components/text-helpers/generic-landing-content-wrapper"
import SEO from "../../components/seo"
import styles from "./index.module.scss"
import localize from "../../components/localize"

const Content = ({ languages, locale }) => {

  let site = useContext(ThemeContext)
  let translations = site.translations

  return ( 
    // for future flag page design
    
    // <div className={`${styles.flagsContainer}`}>
    //   {languages.map(x => (
    //     <div className={`${styles.flag}`}>
    //       <div className={`${styles.logoImage}`}>
    //         <Link to={r.get(locale, translations, "casinoLanguage", x.name)}>
    //           <img
    //             alt={x.name}
    //             src={`../../../icons/flags/${x.name}.png`}
    //           />
    //         </Link>
    //       </div>
    //       <p>{x.name}</p>
    //       <div className={`${styles.viewCasinosButton}`}>
    //         <Link to={r.get(locale, translations, "casinoLanguage", x.name)}>
    //           <Button
    //             variant="contained"
    //             color="primary"
    //           >
    //             {t.get(translations, 'gp-view-casinos')}
    //           </Button>
    //         </Link>
    //       </div>
    //     </div>
    //   ))}
    // </div>
    <div className={`${styles.flagsContainer}`}>
      {languages.map(x => (
        <p className={`${styles.flag}`}>
          <Link to={r.get(locale, translations, "casinoLanguage", x.name)}>
            {x.name}
          </Link>
        </p>
      ))}
    </div>
  )
}

const Index = ({ location, pageContext, data }) => {
  let site = useContext(ThemeContext)
  const { locale, alternateLocaleSlugs } = pageContext

  let pageData = data.sanityGenericLandingPage
  let languages = data.allSanityLanguage.edges.map(x => x.node);

  return (
    <Layout breadcrumbNameOverride={pageData.breadcrumbName} silo={data.sanitySilo} pageContext={pageContext} location={location}>
      <SEO location={location} alternateSlug={alternateLocaleSlugs}
        title={pageData && pageData.seoTitle}
        description={pageData && pageData.seoMeta}
      />
      <GenericLandingContentWrapper pageData={pageData}>
        <Content languages={languages} locale={locale} />
      </GenericLandingContentWrapper>
    </Layout>
    
  )
}

export default localize(Index)
export const query = graphql`
  query CasinoLangaugeLandingQuery {
    sanitySilo (_id: {eq: "c2f8b64b-b695-44fd-beb1-daca06999131"}){
        _id
        breadcrumbTrail {
            ...LocaleSiloTrails
        }
    }
    sanityGenericLandingPage(
      name: { eq: "Casino Language" }
    ) {
      ...GenericLanding
    }
    
    allSanityLanguage {
      edges{
        node {
            name{
                ...LocaleString
            }  
        }
      }
    }
  }
`
